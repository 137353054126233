<template>
	<div>
		<!-- 
			Backend Errors 
		-->
		<div v-if="isError === true">
			<div v-for="error in errors" :key="error">
				<div v-for="el in error" :key="el">
					<ion-item>
						<div tabindex="0"></div><!-- bug fix for safari 14 -->
						<ion-text color="danger">{{el}}</ion-text>
					</ion-item>
				</div>
			</div>
		</div>
		<!-- 
			Image 
		-->
		<ion-item v-if="imageUrl != null">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-img :src="imageUrl" />
		</ion-item>
		<!-- 
			Category 
		-->
		<ion-item @click="updateRoute('/classified-categories')" button detail>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			Category
		</ion-item>
		<!-- Category Chips -->
		<ion-item v-if="category != null && subCategory != null">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-text class="text-capitalize margin-right-sm">{{category.replace('-', ' ')}}</ion-text>
			<ion-icon class="small-icon" :icon="chevronForward" />
			<ion-text class="text-capitalize margin-left-sm">{{subCategory}}</ion-text>
		</ion-item>
		<!-- 
			Price 
		-->
		<ion-item v-if="category == 'for-sale'">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="price" type="text" placeholder="Price"></ion-input>
		</ion-item>
		<!-- 
			Title 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="title" type="text" placeholder="Title"></ion-input>
		</ion-item>

		<!-- 
			Body 
		-->
		<div>
			<TextWrapper 
				:store="store.state"
				input-type="textarea"
				:is-mentions="true" 
				:is-hashtags="true" 
				:has-button="false" 
				:set-delimeter="null"
				@input-data="getInputData" />
		</div>

		<!-- 
			Status 
		-->
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-select placeholder="Post Visibility" v-model="status">
				<ion-select-option :value="1">Published</ion-select-option>
				<ion-select-option :value="0">Unpublished Draft</ion-select-option>
			</ion-select>
		</ion-item>
		<!-- 
			Actions 
		-->
		<ion-item lines="none">
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-button v-if="imageUrl === null" slot="start" expand="full" size="small" color="light" @click="takePicture()">
				<ion-icon :icon="image"></ion-icon>
			</ion-button>
			<ion-button v-if="imageUrl != null" slot="start" expand="full" size="small" color="danger" @click="imageUrl = null">
				<ion-icon :icon="trash"></ion-icon>
			</ion-button>
			<ion-button slot="end" expand="full" size="small" color="primary" @click.prevent="storePost()">
				Post
			</ion-button>
		</ion-item>
	</div>
</template>

<script>
import { IonItem, IonSelect, IonInput, IonSelectOption, IonButton, IonText, loadingController, toastController, IonImg, IonIcon } from '@ionic/vue';
import { defineComponent, computed, ref } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';
const { Camera } = Plugins;
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
import { image, trash, chevronForward } from 'ionicons/icons';
import TextWrapper from './TextWrapper.vue'

export default defineComponent( {
	components: { IonItem, IonSelect, IonInput, IonSelectOption, IonButton, IonText, IonImg, IonIcon,  TextWrapper },
	name: 'ClassifiedForm',
	setup() {
		const router = useRouter();
		const isError = ref(false);
		const errors = ref([]);
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const authToken = computed(() => store.state.authToken);
		const title = ref(null);
		const bodyText = ref(null);
		const status = ref(1);
		const timeout = { default: 6000 }
		const toastMessage = ref(null);
		const imageUrl = ref(null);
		const category = computed(() => store.state.category);
		const subCategory = computed(() => store.state.subCategory);
		const price = ref(null);

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

						}
					}
				]
			})
			return toast.present();
		}

		function resetForm() {
			title.value = null
			bodyText.value = null
			status.value = 1
			imageUrl.value = null
			price.value = null
		}

		function storePost() {
			presentLoading()
			apiClient.post('/api/store/post', 
				{
					title: title.value,  
					body: bodyText.value, 
					status: status.value, 
					post_type: 'classified',
					image: imageUrl.value,
					category: category.value,
					sub_category: subCategory.value,
					price: price.value          
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					// Success
					if(response.data.message == 'success') {
						toastMessage.value = 'Your post has been created.'
						openToast()
						isError.value = false
						errors.value = []
						resetForm()
						store.commit('setFeedTrigger', true);
						store.commit('setCategory', 'gigs');
						store.commit('setSubCategory', null);
						router.push('home')
					}
					// Fail Validation
					if(response.data.message == 'fail-validation') {
						toastMessage.value = 'There was a problem.'
						openToast()
						isError.value = true
						errors.value = response.data.errors            
					}
					// Fail Auth
					if(response.data.message == 'fail-auth') {
						toastMessage.value = 'You must be signed in to update your profile.'
						openToast()
					}
				}).catch(error => {
					// credentials didn't match
					isError.value = true
					errors.value = error.response.data.errors
				});        

		}

		async function takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					imageUrl.value = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		}

		function getInputData(e) {
			if(e) {
				bodyText.value = e.text
			}
			//console.log('test' + e.text)
		}

		function updateRoute(val) {
			router.push(val)
		}

		return { authUser, authToken, title, bodyText, status, presentLoading, storePost, isError, errors, resetForm, router, imageUrl, takePicture, image, trash, category, subCategory, price, getInputData, store, updateRoute, chevronForward }
	}
});
</script>

<style scoped>
	.small-icon {
		font-size:14px;
	}
</style>
