<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="create-classified" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- If User is Authorized -->
        <div v-if="isAuth === true">
          <ClassifiedForm />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import ClassifiedForm from '../../components/CreateClassified.vue'

export default defineComponent({
  name: 'CreateClassified',
  components: {
    IonContent,
    IonPage,
    ModalHeader,
    ClassifiedForm
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {


    return {

    }
  }
});
</script>

<style scoped>

</style>